import React from "react";

function Test() {
  return (
    <div>
      <h1>asdasd</h1>

      {/* line  */}
      {/* <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/154f4ad444edacd2064a43e592c4b09315da08a462347a550e23e91f51072f51?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
                  className="object-contain aspect-[10.75] min-w-[240px] w-[1955px]"
                /> 
                 <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8cbc5a13d68621ede8f1aa8d55a5b3ab90ac060de610de2588c665ddfa145496?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
          className="object-contain aspect-[16.39] w-[1280px] max-md:max-w-full"
        /> 
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d29c06c8df2f89537a66f211d61014e2643359015d6fb0e83cb1b3da7b8c6b3?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
          className="object-contain aspect-[13.33] w-[1280px] max-md:max-w-full"
        />
        */}

      {/* slider line */}
      {/* <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6a89ef13a2cba21fc689760d4c3afce8f14eb42b58678fdf8e34ee86e3cbb19?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
            className="object-contain shrink-0 self-stretch my-auto aspect-[6.94] w-[188px]"
          /> 
           <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b360996de8f8e19e09dac680b5f6e3ae23323777210439ed783b307a647db2d?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
            className="object-contain shrink-0 self-stretch my-auto aspect-[6.94] w-[188px]"
          />*/}
               
               

          
    </div>
  );
}
export default Test;
