import React from 'react';
import slide_image_1 from "../../assets/images/404.jpg";
const Page404 = () => {
    return (
        <main className='flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24'>
            <section className="single_equipment" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <div className="content_area">
                    <div id="notfound">
                        <div className="notfound">
                            <div className="notfound-404">
                                <img src={slide_image_1} alt="404" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Page404;
