import React from 'react'

function Footer() {
  return (
    <div className="mt-9 text-xl text-center text-red-600 uppercase max-md:max-w-full">
                Copyright © 2060 Switch Tech. All Rights Reserved.
                <br />
            </div>
  )
}

export default Footer