import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/home";
import './index.css';
import AboutUs from './pages/About-Us/about-us';
import ContactUs from "./pages/Contact-Us/contact-us";
import Services from "./pages/Services/services";
import Work from "./pages/Work/work";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Test from './pages/test/test';
import Page404 from "./pages/Page not Found/Page404";
import Zenith from "./pages/Zenith/zenith";


function App() {
  return (
    <div className="flex overflow-hidden flex-col pb-20 bg-gray-200 ">

      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/work' element={<Work />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/zenith' element={<Zenith />} />
        <Route path='/test' element={<Test />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
