import * as React from "react";

function Zenith() {
    return (
        <>
            <div className="flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24">
                <div className="flex flex-wrap gap-3 items-center self-center mt-28 ml-3 uppercase max-md:mt-10 max-md:max-w-full">
                    <div className="self-stretch my-auto text-5xl font-black text-red-600  max-md:text-4xl">
                        Zenith
                    </div>
                    <div className="self-stretch my-auto text-3xl font-extralight text-black ">
                        <span className="font-medium text-neutral-800">
                            Your Ai Assistant
                        </span>{" "}
                    </div>
                </div>
                {/* <div className="mt-20 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col">
                        <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                            <svg width="400" height="95" viewBox="0 0 473 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M-391 93H329.013L410.414 11H462" stroke="black" stroke-width="4" />
                                <circle cx="462" cy="11" r="11" fill="black" />
                            </svg>

                        </div>
                        <div className="flex flex-col w-[52%] max-md:ml-0 max-md:w-full ">
                            <div className="flex flex-col w-full max-md:max-w-full justify-center">
                                <div className="flex flex-wrap gap-10  max-w-full text-5xl font-black text-red-600 uppercase w-[937px] max-md:text-4xl text-center">
                                    <div className="flex-auto gap-2 self-stretch p-2 max-md:max-w-full max-md:text-4xl">
                                        Get in Touch
                                        <span className="text-black "> with Zenith</span>
                                    </div>
                                </div>
                                <div className="mt-4 text-xl font-extralight text-black max-md:mr-1.5 max-md:max-w-full">
                                    Zenith is not just an AI, it’s a glimpse into the future of
                                    communication and connectivity
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                            <svg width="400" height="95" viewBox="0 0 471 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M864 93H143.987L62.5859 11H11" stroke="black" stroke-width="4" />
                                <circle cx="11" cy="11" r="11" transform="matrix(-1 0 0 1 22 0)" fill="black" />
                            </svg>

                        </div>
                    </div>
                </div>
                <div className="self-center mt-10 p-14 w-full max-w-[1799px] max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col">
                        <div className="flex flex-col w-[39%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col items-start mt-40 w-full text-6xl font-black text-red-600 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                                <div className="gap-2 self-stretch p-2 uppercase max-md:max-w-full max-md:text-4xl">
                                    <span className="font-extralight text-black capitalize">
                                        Talk with
                                    </span>{" "}
                                    Zenith{" "}
                                </div>
                                <div className="mt-7 uppercase max-md:text-4xl">or</div>
                                <div className="gap-2 self-stretch p-2 mt-7 uppercase max-md:max-w-full max-md:text-4xl">
                                    <span className="font-extralight text-black capitalize">
                                        chat with
                                    </span>{" "}
                                    Zenith{" "}
                                </div>
                                <div className="overflow-hidden gap-2 self-stretch px-7 pt-6 mt-14 text-2xl font-extralight text-black rounded-2xl bg-zinc-300 min-h-[707px] pb-[667px] max-md:px-5 max-md:pb-24 max-md:mt-10">
                                    the future is just a message away
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/48ab8469f2fb3266dd12ab8751429101b0e44383ec822fe7e244b85f7b329d90?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                                className="object-contain grow w-full aspect-[0.87] max-md:mt-10 max-md:max-w-full"
                            />
                        </div>
                    </div>
                </div>
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ceb93139f32227eae07738a80bce5f86554bf7e9175d1084de7887a42ab3127e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain mt-16 w-full aspect-[5.21] max-md:mt-10 max-md:max-w-full"
                /> */}

            </div>
           
        </>
    );
}
export default Zenith;