import React from 'react';
import ImageSlider from '../../components/ImageSlider';
function Work() {
  return (
    <div className="flex overflow-hidden flex-col items-center  py-32 bg-gray-200">
            <div className="flex overflow-hidden relative flex-wrap gap-40 justify-center items-start self-stretch pt-2 pr-1.5 pl-8 mt-28 w-full text-5xl font-black  leading-none text-center text-red-600 min-h-[118px] max-md:pl-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dc12134a5749b8600b686b441ad77f91d8403a43e5e23e8bd667d21a15cc299?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain absolute left-0 bottom-4 z-0 aspect-[8.77] h-[74px] min-w-[240px] w-[651px] max-md:max-w-full"
                />
                <div className="min-w-[240px] max-md:text-4xl">OUR WORK</div>
            </div>
            <div className=" my-20 text-5xl font-medium uppercase text-neutral-700 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                <span className="">Trusted by </span>
                <span className="font-black text-red-600">Leading </span>
                <span className="">Brands</span>
            </div>
            {/* <div className="flex overflow-hidden gap-10 items-center mt-16 w-full max-w-[1215px] min-h-[222px] max-md:mt-10 max-md:max-w-full">
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b985cb5a8bd4f1f8f3a6b62d1bfaab20206852b5c33f11e178ba6e266656df5c?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[156px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
                />
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5a53fc27afb2f2bd43486e40c43cd6973bccf6a05cdb35121a80bde469ee614e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[0.67] w-[101px]"
                />
            </div> */}
            <ImageSlider />
            <div className="gap-2 self-stretch p-2 mt-16 text-5xl font-medium text-black uppercase max-md:text-4xl text-center">
                <span className="font-normal">Elevating Brands, </span>
                <span className="font-black text-red-600">Shaping Futures</span>
            </div>
            <div className="flex flex-wrap gap-6 items-start mt-16 w-full max-w-[1216px] max-md:mt-10 max-md:max-w-full">
                <div className="flex overflow-hidden flex-col grow shrink items-center px-px  aspect-square min-w-[240px] w-[331px]">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/72851c817ecdb5a35305087b0792de5b637490c4083bc14e5f7aa5e23d8b14be?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square"
                    />
                </div>
                <div className="flex overflow-hidden flex-col grow shrink  min-w-[240px] w-[396px] max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a913936818d81119cf7651451061b052ca31bf7d11eea61be3195f20d4b0d32d?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square max-md:max-w-full"
                    />
                </div>
                <div className="flex overflow-hidden flex-col grow shrink items-center  aspect-square min-w-[240px] w-[327px]">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/43532a53644c069c1b2b37a982f5e83f99d1daa708505dcce884fac670f0f9c4?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square"
                    />
                </div>
                <div className="flex overflow-hidden flex-col items-center  h-[189px] w-[188px]">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0c76e451d0792afe0845e9bc99ce97efbba51d9ff318b7717b7626d14972bd35?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain aspect-square w-[188px]"
                    />
                </div>
                <div className="flex overflow-hidden flex-col grow shrink items-center  aspect-square min-w-[240px] w-[220px]">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c5646a44699647d7c86c4d791d6392ea8a37265e426d24f190ba4d2e0d6266?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square"
                    />
                </div>
                <div className="flex overflow-hidden flex-col grow shrink  min-w-[240px] w-[390px] max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/58fcd9c541befbe997a1db6cd7296d0b082825f5c22c3f2341dbbaa467116eb0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square max-md:max-w-full"
                    />
                </div>
                <div className="flex overflow-hidden flex-col grow shrink items-center  aspect-square min-w-[240px] w-[232px]">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ada71e2a0aa451eb2cca4a6c921b1b9daff9fcd32f8aa2625f30dbd85ee456b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain w-full aspect-square"
                    />
                </div>
            </div>
            {/* <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ce6fa9c9919cb11370d48eb13c18c150f0a2365a7891e6b686c20824fcabd28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                className="object-contain mt-16 max-w-full aspect-[5.52] w-[149px] max-md:mt-10"
            /> */}
            <div className="flex flex-col mt-20 max-w-full text-xl font-bold w-[151px] cursor-pointer">
              <div className="-mb-5 text-red-600">View More</div>
              <svg
                width={150}
                height={26}
                viewBox="0 0 150 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 25H125L149 1" stroke="black" />
              </svg>
            </div>
            <div className="flex flex-col justify-center self-stretch py-2 mt-20 w-full text-4xl font-medium text-white uppercase max-md:mt-10 max-md:max-w-full">
                <div className="flex relative flex-col gap-2.5 self-stretch px-2.5 py-24 w-full min-h-[275px] max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-cover absolute inset-0 size-full"
                    />
                    <span className="z-10 bg-transparent text-center text-nowrap">make your story a part of our <span className="font-black text-red-600 ">success journey</span></span>{" "}

                </div>
            </div>
        </div>
  )
}

export default Work
