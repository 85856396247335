
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './index.css'

import slide_image_1 from "../assets/images/project-1.png";
import slide_image_2 from "../assets/images/project-2.png";
import slide_image_3 from "../assets/images/project-4.png";
import slide_image_4 from "../assets/images/project-1.png";
import slide_image_5 from "../assets/images/project-2.png";
import slide_image_6 from "../assets/images/project-4.png";
import slide_image_7 from "../assets/images/project-2.png";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
// import { EffectCoverflow, Pagination, Navigation } from "swiper";

function SwiperSlider() {
  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        // modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" />
        </SwiperSlide>

        <div className="slider-controler ">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </Swiper>
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-col mt-4 max-w-full text-xl font-bold w-[151px] cursor-pointer text-red-600 text-center justify-center">
          <Link to={"/work"} >
            <span className="-mb-10">

              View More
            </span>
            <svg
            className="-mt-3"
              width={150}
              height={26}
              viewBox="0 0 150 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 25H125L149 1" stroke="black" />
            </svg></Link >
        </div>
      </div>
    </div>
  );
}
export default SwiperSlider;
