import React from "react";
import Footer from "./../../components/Footer";
import CarouselDigtal from "./../../components/CarouselDigtal";
import Carousel from "./../../components/Carousel";
import TechServiceSlider from "../../components/TechServiceSlider";
import DigitalServiceSlider from "../../components/DigitalServiceSlider";
import SwiperSlider from "./../../components/SwiperSlider";
import ImageSlider from "../../components/ImageSlider";
import { Link } from "react-router-dom";
import AssistantSlider from "../../components/AssistantSlider";

function Home() {
  return (
    <div className="flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24">
      <div className="flex flex-col">
        {/* <div className="z-10 w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full md:p-1 p-10">
              <div className="flex overflow-hidden flex-col self-stretch my-auto mr-0 text-5xl font-bold text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                <div className="flex overflow-hidden flex-col w-full max-w-[591px] max-md:max-w-full max-md:text-4xl">
                  <div className="overflow-hidden self-stretch w-full max-md:max-w-full max-md:text-4xl">
                    <span className="font-black text-red-600">Rebirth</span>
                    <span className="font-medium text-black">the </span>
                    <span className="font-extrabold text-black">Future </span>
                    <span className="font-medium text-black">of </span>
                    <span className="text-black">AI</span>
                    <span className="font-medium text-black"> and </span>
                    <br />
                    <span className="text-black">Tech </span>
                    <span className="font-extrabold text-black">industry!</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[55%] -mt-36 max-md:ml-0 max-md:w-full" >
              <div className="flex overflow-hidden flex-col grow justify-center p-2 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                  className="object-contain w-full aspect-[1.3] max-md:max-w-full"
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="z-10 w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-[49%] max-md:ml-0 max-md:w-full md:p-1 p-10">
              <div className="flex overflow-hidden flex-col self-stretch my-auto mr-0 text-5xl font-bold text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-3xl max-sm:text-2xl">
                <div className="flex overflow-hidden flex-col w-full max-w-[710px] max-md:max-w-full max-md:text-3xl max-sm:text-2xl mb-10 -mt-14">
                  <div className="overflow-hidden self-stretch w-full max-md:max-w-full text-6xl max-sm:text-2xl md:ps-10 py-32">
                    <span className="font-black text-red-600">Rebirth</span> <br />
                    <span className="font-medium text-black"> The </span>
                    <span className="font-extrabold text-black">Future </span>
                    <span className="font-medium text-black">of </span>
                    <span className="text-black">AI</span>
                    <span className="font-medium text-black"> and </span>
                    <br />
                    <span className="text-black">Tech </span>
                    <span className="font-extrabold text-black">industry!</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[50%] -mt-36 max-md:ml-0 max-md:w-full">
              <div className=" overflow-hidden flex-col grow justify-start  max-md:max-w-full hidden md:flex z-50">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2ff37da81a93056ebb913b1ee62d3437b933648b771a91cac07313676556a2?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                  className="object-contain w-full aspect-[1.4] max-md:max-w-full max-md:w-[80%] max-sm:w-[70%] z-50"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full ml-16 max-md:max-w-full -mt-52">
          <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
            <div className=" grow gap-2 items-start py-2   hidden md:flex">
              <svg
                width="100%"
                height="112"
                preserveAspectRatio="none"
                viewBox="0 0 1202 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1540.73 0H1955V3.02703H1542.21L1411.86 112H502.674L431.408 52.4177H0V49.3906H432.888L504.154 108.973H1410.38L1540.73 0Z"
                  fill="#2F2F2F"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col max-md:ml-0 max-md:w-full">
            <div className="flex gap-10 items-center -mt-8 mr-0 text-lg text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-stretch my-auto">
                <span className="font-extralight text-zinc-800">
                  Web/Mobile
                </span>
                <span className="font-medium text-red-600"> Apps</span>
              </div>
              <div className="self-stretch my-auto">
                <span className="font-medium text-red-600">AI </span>
                <span className="font-extralight text-zinc-800">
                  Innovation
                </span>
              </div>
              <div className="self-stretch my-auto">
                <span className="font-medium text-red-600">Digital </span>
                <span className="font-extralight text-zinc-800">
                  Marketing Agency
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center w-full max-md:max-w-full">
        <div className="flex z-10 pl-10 mt-5 w-full max-md:pl-10 max-md:mt-0 max-md:max-w-full">
          <div className="flex w-2/4 flex-col text-red-600 pt-28">
            <div className="text-6xl font-black uppercase max-md:max-w-full max-md:text-4xl">
              Shaping < br /> Tomorrow, Today...
            </div>
            <div className="mt-4 font-medium max-md:max-w-full">
              Welcome to Switch,
            </div>
            <div className="mt-4 font-extralight text-black max-md:max-w-full">
              Where innovation meets inspiration. We’re not just a company,
              We’re pioneers of the future, redefining the way

              technology shapes our world.

              At the heart of our vision stands Zenith, Our AI-powered assistant
              and the embodiment of our commitment to innovation and
              connectivity
            </div>
            <div className="flex flex-col mt-4 max-w-full text-xl font-bold w-[151px] cursor-pointer">
              <div className="-mb-5 ">Explore More</div>
              <svg
                width={150}
                height={26}
                viewBox="0 0 150 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 25H125L149 1" stroke="black" />
              </svg>
            </div>
          </div>
          <div className="flex w-2/4 relative items-center justify-center flex-col">
            <span className="text-5xl font-black uppercase max-md:max-w-full max-md:text-4xl text-red-600 flex items-center">
              Zenith
              <span className="text-[#212121] text-base font-normal">&nbsp; &nbsp; Your Ai Assistant</span>
            </span>
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e7921b289f0fd1e2d6cd1ae32532922e3bd8a8fe340eca0a23a8cc0dac43cc5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
              className="object-contain w-full aspect-[1.14] max-w-[737px]"
            />

          </div>
        </div>

        <div className="flex relative flex-col items-center self-stretch  w-full text-6xl font-black text-red-600 uppercase min-h-[141px] max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          <svg
            width="100%"
            height="96"
            preserveAspectRatio="none"
            viewBox="0 0 1280 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1042.45 103H1330V100.892H1043.47L953 25H321.915L272.448 66.4948H-27V68.603H273.475L322.943 27.1081H951.973L1042.45 103Z"
              fill="#2F2F2F"
            />
          </svg>

          {/* <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8cbc5a13d68621ede8f1aa8d55a5b3ab90ac060de610de2588c665ddfa145496?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
            className="object-contain aspect-[16.39] w-[1280px] max-md:max-w-full"
          /> */}

          <div className="relative z-10 gap-2 p-2 mb-0 mt-16 max-md:mb-2.5 max-md:max-w-full max-md:text-4xl">
            Our Services
          </div>
        </div>
        <div className="gap-2 self-stretch p-2 mt-6 text-6xl leading-none text-center text-black max-md:text-4xl">
          Tech Services
        </div>
        <div className="mt-4 mb-12 text-2xl text-black max-md:max-w-full">
          <span className="text-red-600">Driving Future Innovation: </span>Our
          Comprehensive Tech Services
        </div>
        {/* <Carousel /> */}
        <TechServiceSlider />

        <div className=" w-full  mt-20">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3165714768ed1a2fb867c18f308edb02828730477bf99e4dd46b41ddd3ca9587?apiKey=e969867fc0a145258ec2d2dcaf1c3295&"
            className="object-contain max-w-full aspect-[2.72] "
          />
        </div>

        <div className="gap-2 self-stretch p-2 mt-16 text-6xl leading-none text-center text-black max-md:mt-10 max-md:text-4xl">
          Digital Marketing Services
        </div>
        <div className="mt-3 mb-20 text-2xl text-black max-md:max-w-full">
          <span className="text-red-600">Futuristic Marketing Solutions: </span>{" "}
          Pioneering Tomorrow's Strategies
        </div>
        <DigitalServiceSlider />
        {/* <CarouselDigtal /> */}

        <div className="relative w-full mb-15 mt-10">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5475a44f6c2c8bdb3e6c81918c8d3bdb5d1ed8f7963a2469d8f19f4f69fea0aa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain w-full aspect-[2.75] max-md:max-w-full"
          />
        </div>


        <div className="flex max-md:flex-col mt-16">
          <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full">
            <div className="text-start mt-10 text-5xl font-normal text-black uppercase ms-48">
              <span className="text-nowrap ">Try your</span> <br />
              <span className="font-extrabold text-red-600 text-nowrap">
                new costless assistant
              </span>
            </div>
          </div>
          <div className="flex flex-col w-[70%] max-md:ml-0 w-full justify-center">
            <span className="font-extrabold text-[#5C5C5C] text-end me-48 text-4xl -mb-16 capitalize">
              OUR <span className="font-extrabold text-red-600">NEW </span>{" "}
              PRODUCT
            </span>
            <svg
              width="1000"
              height="78"
              viewBox="0 0 1000 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1264.17 0H1546V2.10811H1265.17L1176.5 78H557.973L509.49 36.5052H0V34.397H510.497L558.98 75.8919H1175.49L1264.17 0Z"
                fill="#2F2F2F"
              />
            </svg>
          </div>
        </div>

      </div>
      <div className="flex flex-col items-center text-2xl font-black text-zinc-800 mt-24">
        <div className="flex gap-5 justify-center items-center  max-w-full  max-md:mr-2.5">
          {/* <div className="flex flex-col items-center pt-2  ">
            <img src="/images/Frame 65.png" width="300" height="200" alt="assestanet" className="object-contain max-w-full aspect-[0.66] " />
            
          </div>
          <div className="flex flex-col items-center pt-2  ">
            <img src="/images/Frame 63.png" width="300" height="200" alt="assestanet" className="object-contain max-w-full aspect-[0.66] " />
          </div>
          <div className="flex flex-col items-center pt-2  ">
            <img src="/images/Frame 66.png" width="300" height="200" alt="assestanet" className="object-contain max-w-full aspect-[0.66] " />
          </div>
          <div className="flex flex-col items-center pt-2  ">
            <img src="/images/Frame 61.png" width="300" height="200" alt="assestanet" className="object-contain max-w-full aspect-[0.66] " />
          </div> */}
          <AssistantSlider />

          {/* <div className="flex flex-col items-center pt-2  rounded-2xl border border-solid border-stone-300 h-[321px] w-[203px]">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/109cf05535e1aa30cc1f0b94752bd70ae5998986b3f16b29d69b6cb20774eb2a?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            // className="object-contain max-w-full aspect-[0.66] w-[369px]"
            />
            <div className="">Teacher</div>
          </div>*/}
        </div>
      </div>

      <div className="flex flex-col items-center w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex relative flex-col items-center self-stretch mt-28 w-full text-6xl font-bold text-black uppercase min-h-[141px] max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          <svg
            width="100%"
            height="96"

            preserveAspectRatio="none"
            viewBox="0 0 1280 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1042.45 103H1330V100.892H1043.47L953 25H321.915L272.448 66.4948H-27V68.603H273.475L322.943 27.1081H951.973L1042.45 103Z"
              fill="#2F2F2F"
            />
          </svg>

          <div className="relative z-10 gap-2 p-2 max-md:mb-2.5 max-md:max-w-full text-5xl -mt-10 mb-20">
            <span className="font-normal">Trusted by</span>
            <span className="font-black text-red-600"> Leading</span>
            <span className="font-normal"> Brands</span>
          </div>
        </div>
        <ImageSlider />
        {/* <div className="flex overflow-hidden gap-10 items-center mt-9 w-full max-w-[1795px] min-h-[222px] max-md:max-w-full">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/95fe26d3ffac2868c63d6f2559a95c46b6f361d1264a5e5473812ff522389ace?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[156px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5dbfd64977ba7a935e7627057769bf002ad91c301439a4cc2328076c8292ffa?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2d83a27c710fc8401b5e8c5597bbebbcb066a384cb57226672f7c436d7daaa4b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b58a74504f07b9d78d6a55658d56c7432076c4db4d562e2f6e8ffb86194a3b5?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c88e1f56b7e5612b7c81e24b129f33faf30692878e7cfe15dd67847a2363fc7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c30fbbda26cc83eff4f31ff9f595b651a3aa624cdece38004932b38a5aacac36?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/67c31151c870557df90eaa0761b8653ec1a6024f932e1bda890cfd7a1e862b66?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3afe8b49c4a52dcff4cd7e6da03e4258294fd51eeb155ed8aa0d9fe329415444?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c5212947a470ed933f2334bf349bc3de69148358275e07bc35fe393189244b6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[150px]"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3e62490bfa754ca875fd0f96cdde8232a9d552ecaeb432636ead63fb88192695?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-contain shrink-0 self-stretch my-auto aspect-[0.93] w-[139px]"
          />
        </div> */}
        <div className="gap-2   p-2 text-6xl font-medium text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          <span className="font-normal">Elevating Brands, </span>
          <span className="font-black text-red-600">Shaping Futures</span>
        </div>
        <div className="flex flex-wrap gap-10 mt-28 ml-5 w-full max-w-[1753px] max-md:mt-10 max-md:max-w-full">
          {/* <div className="flex gap-2 items-center p-2 my-auto">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6663aa4dc66b528c71c7e9030f257795673118b77b48bd83d68f0a27ade1e755?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
              className="object-contain self-stretch my-auto aspect-[0.55] w-[30px]"
            />
          </div> */}
          {/* <div className="flex-auto max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
                <div className="flex relative flex-col items-start pt-96 pr-48 pl-64 pb-[579px] max-md:px-5 max-md:py-24 max-md:max-w-full">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/95d8ac3f19c2712461b6ace8a40c713f03d2c730d7212806affd9a4fdbea4ee7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain absolute right-0 bottom-0 z-0 max-w-full aspect-square h-[1000px] w-[1000px]"
                  />
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/03de2395442c5b3e8788ad28cd2e242e6f2892efaaffe936a1e28189c6a76ef9?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-contain z-0 max-w-full aspect-[8.2] w-[533px]"
                  />
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
                <div className="flex relative flex-col justify-center items-end px-16 py-96 mt-36 w-full min-h-[800px] max-md:px-5 max-md:py-24 max-md:mt-10 max-md:max-w-full">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b99c4aabde45b4152bc26dbba9e4467bbb13f9c0d146fa047ac9bbb4570a8?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="flex relative gap-2 items-center p-2 rotate-[3.141592653589793rad]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/812732898e4b012c9b9b9ccefbf78b12d413cb63780c0087cf133c942fc4f53e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                      className="object-contain self-stretch my-auto aspect-[0.55] w-[30px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <SwiperSlider />
        </div>

        <svg
          width="100%"
          height="96"
          viewBox="0 0 1280 96"
          preserveAspectRatio="none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1141.07 0H1494V2.5946H1142.33L1031.29 96H256.729L196.016 44.9294H-442V42.3348H197.276L257.99 93.4054H1030.03L1141.07 0Z"
            fill="#2F2F2F"
          />
        </svg>

        {/* contact us section */}
        <div className="flex relative flex-col gap-2 justify-center items-center self-stretch w-full text-white min-h-[568px] max-md:px-5 max-md:pt-24 max-md:mt-10 max-md:max-w-full mt-20">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6c02d821b4d992ef9573a7b729a447b10a5c5f37fdeff7eefc7d07ef0e3145b0?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-cover absolute inset-0 size-full"
          />
          <div className="relative self-stretch mt-auto text-5xl font-black text-center px-48 capitalize ">
            No matter how you choose to reach out, we're here to listen,
            collaborate, and create a brighter future
            <span className="text-red-600"> together</span>
          </div>
          <div className="flex relative gap-0 items-center self-stretch mb-auto text-4xl font-semibold justify-center">
            <div className="flex items-center gap-2 self-stretch px-10 py-8 my-auto rounded-lg  max-md:px-5 ">
              <Link to={"/contact-us"}>Contact US</Link >
              <svg
                width="48"
                height="18"
                viewBox="0 0 48 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 9.99997H43.586L37.293 16.293L38.707 17.707L47.414 8.99997L38.707 0.292969L37.293 1.70697L43.586 7.99997H0V9.99997Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
