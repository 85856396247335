import React from "react";



import slide_image_1 from "../assets/images/pepsi.png";
import slide_image_2 from "../assets/images/iwan.png";
import slide_image_3 from "../assets/images/merck.png";
import slide_image_4 from "../assets/images/dunkin.png";
import slide_image_5 from "../assets/images/abbott.png";
import slide_image_6 from "../assets/images/iwan.png";
import slide_image_7 from "../assets/images/merck.png";
import slide_image_8 from "../assets/images/1.png";
import slide_image_9 from "../assets/images/2.png";
import slide_image_10 from "../assets/images/3.png";
import slide_image_11 from "../assets/images/4.png";
import slide_image_12 from "../assets/images/5.png";
import slide_image_13 from "../assets/images/6.png";
import slide_image_14 from "../assets/images/7.png";
import slide_image_15 from "../assets/images/8.png";
import slide_image_16 from "../assets/images/9.png";
import slide_image_17 from "../assets/images/10.png";
import slide_image_18 from "../assets/images/11.png";
import slide_image_19 from "../assets/images/12.png";
import slide_image_20 from "../assets/images/13.png";
import slide_image_21 from "../assets/images/14.png";
import slide_image_22 from "../assets/images/15.png";
import slide_image_23 from "../assets/images/16.png";
import slide_image_24 from "../assets/images/17.png";
import slide_image_25 from "../assets/images/18.png";
import slide_image_26 from "../assets/images/19.png";
import slide_image_27 from "../assets/images/20.png";
import slide_image_28 from "../assets/images/21.png";
import slide_image_29 from "../assets/images/22.png";
import slide_image_30 from "../assets/images/23.png";
import slide_image_31 from "../assets/images/24.png";
import slide_image_32 from "../assets/images/25.png";
import slide_image_33 from "../assets/images/26.png";
import slide_image_34 from "../assets/images/27.png";
import slide_image_35 from "../assets/images/28.png";
import slide_image_36 from "../assets/images/29.png";
import slide_image_37 from "../assets/images/30.png";
import slide_image_38 from "../assets/images/31.png";
import slide_image_39 from "../assets/images/32.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import './index.css';

function ImageSlider() {
    const slideImages = [
        slide_image_1, slide_image_2, slide_image_3, slide_image_4, slide_image_5,
        slide_image_6, slide_image_7, slide_image_8, slide_image_9, slide_image_10,
        slide_image_11, slide_image_12, slide_image_13, slide_image_14, slide_image_15,
        slide_image_16, slide_image_17, slide_image_18, slide_image_19, slide_image_20,
        slide_image_21, slide_image_22, slide_image_23, slide_image_24, slide_image_25,
        slide_image_26, slide_image_27, slide_image_28, slide_image_29, slide_image_30,
        slide_image_31, slide_image_32, slide_image_33, slide_image_34, slide_image_35,
        slide_image_36, slide_image_37, slide_image_38, slide_image_39
    ];

    return (
        <div className="container">
            <Swiper
                loop={true}
                autoplay={{
                    delay: 1000, // تأخير بين كل حركة بالميلي ثانية
                    disableOnInteraction: false // لاستمرار الحركة بعد التفاعل
                }}
                spaceBetween={50}
                slidesPerView={6}
                onSlideChange={() => console.log("slide")}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[EffectCoverflow, Pagination, Navigation , Autoplay]}

                className="swiper_container swiper_container_img cursor-pointer"
            >
                {slideImages.map((image, index) => (
                    <SwiperSlide key={index} style={{ transition: "none", filter: "unset", height: "10rem" }}>
                        <span className="slide">
                            <img src={image} alt={`slide_image_${index + 1}`} className="w-20 h-40" />
                        </span>
                    </SwiperSlide>
                ))}


                {/* <div className="slider-controler ">
                    <div className="swiper-button-prev slider-arrow">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                     <div className="swiper-pagination"></div> 
                </div>*/}
            </Swiper>
        </div>
    );
}
export default ImageSlider;