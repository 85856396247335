import * as React from "react";
import emailjs from '@emailjs/browser';
import { useState } from "react";

function ContactUs() {

  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Form Data:', formData);
    // example with emailjs:
    const YOUR_SERVICE_ID = "service_3uyvwnw"
    const YOUR_TEMPLATE_ID = "template_7p8cl1m"
    const YOUR_PUBLIC_ID = "nJ9E7tPtewU5U-oGs"
    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, formData, YOUR_PUBLIC_ID)
      .then(response => {
        console.log('Message sent successfully!', response)
        setIsLoading(false);
        alert("Message Sent Successfully");
      })
      .catch(error => console.error('Failed to send message', error));
  };

  return (
    <>
      <div className="flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24">
        <div className="flex flex-col self-end mt-28 w-full text-5xl font-black text-red-600 max-w-[1651px] max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          <div className="flex z-10 flex-col self-center max-w-full uppercase   max-md:text-4xl">
            <div className="flex flex-col w-full max-md:text-3xl">
              <div className="self-stretch w-full max-md:text-3xl">
                Contact us
              </div>
            </div>
          </div>
          <div className="self-end">
            <svg width="640" height="121" viewBox="0 0 640 121" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1636.87 121H2077V117.73H1638.45L1499.97 0H534.043L458.33 64.3702H0V67.6405H459.902L535.616 3.27026H1498.4L1636.87 121Z" fill="#2F2F2F" />
            </svg>
          </div>


          <div className="gap-2 p-2 mt-6 uppercase max-md:max-w-full text-5xl font-black self-center">
            <span className="text-black font-medium">here the future is just </span>a
            message away
          </div>
          <div className="mt-2.5 text-2xl font-extralight text-black max-md:max-w-full self-center">
            Contact us about anything related to our company or services. We’ll
            do our best to get back to you as soon as possible
          </div>
        </div>
        <div className="flex relative flex-col px-20 pt-56 pb-36 mt-20 w-full min-h-[1285px] max-md:px-5 max-md:py-24 max-md:mt-10 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/52aeb6f1b7a976b4ea0c8172602c8532030c9c8432a4a0a181e328cc499318f7?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-cover absolute inset-0 size-full"
          />
          <div className="relative self-end w-full max-w-[1705px] max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:w-full">
                <div className="flex relative flex-col w-full text-4xl text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-3xl">
                  <div className="flex flex-col justify-center p-2 w-full max-md:max-w-full max-md:text-3xl">
                    <div className="gap-2 self-stretch px-1 py-2 w-full max-md:max-w-full max-md:text-3xl">
                      <span className="text-4xl">Have a </span>
                      <span className="text-4xl font-black text-red-600">
                        question
                      </span>
                      <span className="text-4xl">, an </span>
                      <span className="text-4xl font-black text-red-600">
                        idea
                      </span>
                      <span className="text-4xl">,</span>
                      <br />
                      <span className="text-4xl">or a </span>
                      <span className="text-4xl font-black text-red-600">
                        collaboration
                      </span>
                      <span className="text-4xl"> in mind?</span>
                    </div>
                  </div>
                  <div className="gap-2 self-start p-2 max-md:max-w-full max-md:text-4xl">
                    <br />
                    <span className="text-4xl capitalize">
                      Reach out to our AI-powered
                    </span>
                    <br />
                    <span className="text-4xl capitalize">assistant</span>
                    <br />
                    <span className="text-4xl text-red-600 capitalize">
                      ready to assist you 24/7
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:w-full">
                <div className="flex relative flex-col self-stretch my-auto w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-8 self-start text-2xl font-black text-center text-red-600">
                    <svg width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42.1673 11.5001C42.1673 9.39175 40.4423 7.66675 38.334 7.66675H7.66732C5.55898 7.66675 3.83398 9.39175 3.83398 11.5001V34.5001C3.83398 36.6084 5.55898 38.3334 7.66732 38.3334H38.334C40.4423 38.3334 42.1673 36.6084 42.1673 34.5001V11.5001ZM38.334 11.5001L23.0007 21.0834L7.66732 11.5001H38.334ZM38.334 34.5001H7.66732V15.3334L23.0007 24.9167L38.334 15.3334V34.5001Z" fill="black" />
                    </svg>

                    <div className="flex-auto my-auto max-md:max-w-full">
                      Email Address :{" "}
                      <span className="font-extralight text-black">
                        info@switch-ai.us
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-7 items-start mt-11 w-full max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">

                    <div className="flex gap-2">
                      <svg width="26" height="33" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.166 8.95889C30.5013 10.2938 31.5604 11.8786 32.2831 13.6229C33.0057 15.3672 33.3777 17.2368 33.3777 19.1249C33.3777 21.013 33.0057 22.8825 32.2831 24.6268C31.5604 26.3711 30.5013 27.956 29.166 29.2909L19 39.4511L8.83401 29.288C7.49899 27.953 6.44 26.3681 5.71749 24.6238C4.99498 22.8795 4.62311 21.01 4.62311 19.122C4.62311 17.234 4.99498 15.3645 5.71749 13.6202C6.44 11.8759 7.49899 10.291 8.83401 8.95602C10.169 7.621 11.7539 6.56201 13.4982 5.8395C15.2425 5.11699 17.112 4.74512 19 4.74512C20.888 4.74512 22.7575 5.11699 24.5018 5.8395C26.2461 6.56201 27.831 7.62388 29.166 8.95889ZM32.2135 32.3384C34.8272 29.725 36.6071 26.3952 37.3283 22.7701C38.0495 19.145 37.6795 15.3875 36.2652 11.9727C34.8508 8.55796 32.4556 5.63928 29.3824 3.5858C26.3092 1.53232 22.6961 0.436279 19 0.436279C15.3039 0.436279 11.6908 1.53232 8.61763 3.5858C5.54445 5.63928 3.14922 8.55796 1.73486 11.9727C0.320492 15.3875 -0.0494902 19.145 0.671699 22.7701C1.39289 26.3952 3.17286 29.725 5.78651 32.3384L15.9496 42.5044C16.3501 42.9051 16.8257 43.223 17.349 43.4399C17.8724 43.6568 18.4335 43.7685 19 43.7685C19.5666 43.7685 20.1276 43.6568 20.651 43.4399C21.1744 43.223 21.6499 42.9051 22.0504 42.5044L32.2135 32.3384ZM19 24.8749C20.525 24.8749 21.9875 24.2691 23.0659 23.1908C24.1442 22.1124 24.75 20.6499 24.75 19.1249C24.75 17.5999 24.1442 16.1374 23.0659 15.059C21.9875 13.9807 20.525 13.3749 19 13.3749C17.475 13.3749 16.0125 13.9807 14.9341 15.059C13.8558 16.1374 13.25 17.5999 13.25 19.1249C13.25 20.6499 13.8558 22.1124 14.9341 23.1908C16.0125 24.2691 17.475 24.8749 19 24.8749Z" fill="black" />
                      </svg>

                      <div className="grow shrink self-stretch text-2xl font-black text-red-600 w-[337px]">
                        USA Office :{" "}
                        <span className="font-extralight text-black">
                          1746 Northshore
                        </span>
                        <br />
                        <span className="font-extralight text-black">
                          dr Missouri city tx
                        </span>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="flex gap-2 justify-center items-center p-1.5 min-h-[46px]">
                        <svg width="25" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.60203 3.34657H11.1149L13.5361 9.40053L9.64582 11.9941C9.41672 12.147 9.2289 12.354 9.09901 12.5969C8.96912 12.8397 8.90118 13.1109 8.90122 13.3863C8.90624 13.5436 8.90122 13.388 8.90122 13.388V13.4231C8.90221 13.4979 8.90556 13.5727 8.91126 13.6473C8.9213 13.7845 8.93803 13.9703 8.96982 14.1995C9.03508 14.6513 9.16058 15.2738 9.40487 16.0067C9.89681 17.4792 10.8606 19.385 12.738 21.2625C14.6154 23.1399 16.5212 24.1037 17.992 24.5956C18.7266 24.8399 19.3473 24.9638 19.8025 25.0307C20.0593 25.0666 20.3179 25.0889 20.5772 25.0976L20.5989 25.0993H20.6123C20.6123 25.0993 20.7997 25.0893 20.614 25.0993C20.9246 25.0991 21.2291 25.0125 21.4933 24.8491C21.7575 24.6856 21.971 24.4519 22.1099 24.174L23.231 21.9318L30.6535 23.17V30.3986C27.1213 30.909 17.5804 31.4126 10.0842 23.9163C2.58804 16.42 3.09002 6.87721 3.60203 3.34657ZM12.3699 14.1995L15.3934 12.1849C16.0322 11.7588 16.505 11.126 16.7326 10.3927C16.9602 9.6594 16.9287 8.87012 16.6434 8.15728L14.2222 2.10332C13.9737 1.48237 13.5449 0.950117 12.991 0.575212C12.4372 0.200308 11.7837 -4.48331e-05 11.1149 7.52514e-09H3.51502C1.99404 7.52514e-09 0.563408 1.05584 0.314093 2.70571C-0.254812 6.45722 -1.02618 17.5377 7.71823 26.2823C16.4626 35.0269 27.5429 34.2539 31.2943 33.6866C32.9442 33.4356 34 32.0066 34 30.4856V23.17C34.0001 22.3778 33.7192 21.6113 33.2072 21.0067C32.6952 20.4022 31.9854 19.9989 31.204 19.8686L23.7815 18.6321C23.0755 18.5142 22.3504 18.6258 21.7125 18.9505C21.0747 19.2751 20.5577 19.7957 20.2375 20.4359L19.6586 21.5954C19.4538 21.545 19.2512 21.4864 19.0512 21.4197C18.0138 21.0751 16.5731 20.3656 15.104 18.8964C13.6349 17.4273 12.9254 15.9866 12.5807 14.9475C12.4984 14.7018 12.4286 14.4522 12.3715 14.1995H12.3699Z" fill="#0D0D0D" />
                        </svg>

                      </div>
                      <div className="grow shrink text-2xl font-black text-red-600 w-[202px]">
                        (832) 802-0345
                      </div>
                    </div>

                  </div>

                  <div className="flex flex-wrap gap-7 items-start mt-11 w-full max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">

                    <div className="flex gap-2">
                      <svg width="26" height="33" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.166 8.95889C30.5013 10.2938 31.5604 11.8786 32.2831 13.6229C33.0057 15.3672 33.3777 17.2368 33.3777 19.1249C33.3777 21.013 33.0057 22.8825 32.2831 24.6268C31.5604 26.3711 30.5013 27.956 29.166 29.2909L19 39.4511L8.83401 29.288C7.49899 27.953 6.44 26.3681 5.71749 24.6238C4.99498 22.8795 4.62311 21.01 4.62311 19.122C4.62311 17.234 4.99498 15.3645 5.71749 13.6202C6.44 11.8759 7.49899 10.291 8.83401 8.95602C10.169 7.621 11.7539 6.56201 13.4982 5.8395C15.2425 5.11699 17.112 4.74512 19 4.74512C20.888 4.74512 22.7575 5.11699 24.5018 5.8395C26.2461 6.56201 27.831 7.62388 29.166 8.95889ZM32.2135 32.3384C34.8272 29.725 36.6071 26.3952 37.3283 22.7701C38.0495 19.145 37.6795 15.3875 36.2652 11.9727C34.8508 8.55796 32.4556 5.63928 29.3824 3.5858C26.3092 1.53232 22.6961 0.436279 19 0.436279C15.3039 0.436279 11.6908 1.53232 8.61763 3.5858C5.54445 5.63928 3.14922 8.55796 1.73486 11.9727C0.320492 15.3875 -0.0494902 19.145 0.671699 22.7701C1.39289 26.3952 3.17286 29.725 5.78651 32.3384L15.9496 42.5044C16.3501 42.9051 16.8257 43.223 17.349 43.4399C17.8724 43.6568 18.4335 43.7685 19 43.7685C19.5666 43.7685 20.1276 43.6568 20.651 43.4399C21.1744 43.223 21.6499 42.9051 22.0504 42.5044L32.2135 32.3384ZM19 24.8749C20.525 24.8749 21.9875 24.2691 23.0659 23.1908C24.1442 22.1124 24.75 20.6499 24.75 19.1249C24.75 17.5999 24.1442 16.1374 23.0659 15.059C21.9875 13.9807 20.525 13.3749 19 13.3749C17.475 13.3749 16.0125 13.9807 14.9341 15.059C13.8558 16.1374 13.25 17.5999 13.25 19.1249C13.25 20.6499 13.8558 22.1124 14.9341 23.1908C16.0125 24.2691 17.475 24.8749 19 24.8749Z" fill="black" />
                      </svg>

                      <div className="grow shrink self-stretch text-2xl font-black text-red-600 w-[337px]">
                        EGYPT Office :{" "}
                        <span className="font-extralight text-black">
                          23 El-Nahda st.
                        </span>
                        <br />
                        <span className="font-extralight text-black">
                          7 St.,Maadi Sarayat,
                        </span>
                        <br />
                        <span className="font-extralight text-black">
                          El-Maleka tower, Second Floor.
                        </span>
                        <br />
                        <span className="font-extralight text-black">
                          Maadi, Cairo
                        </span>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="flex gap-2 justify-center items-center p-1.5 min-h-[46px]">
                        <svg width="25" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.60203 3.34657H11.1149L13.5361 9.40053L9.64582 11.9941C9.41672 12.147 9.2289 12.354 9.09901 12.5969C8.96912 12.8397 8.90118 13.1109 8.90122 13.3863C8.90624 13.5436 8.90122 13.388 8.90122 13.388V13.4231C8.90221 13.4979 8.90556 13.5727 8.91126 13.6473C8.9213 13.7845 8.93803 13.9703 8.96982 14.1995C9.03508 14.6513 9.16058 15.2738 9.40487 16.0067C9.89681 17.4792 10.8606 19.385 12.738 21.2625C14.6154 23.1399 16.5212 24.1037 17.992 24.5956C18.7266 24.8399 19.3473 24.9638 19.8025 25.0307C20.0593 25.0666 20.3179 25.0889 20.5772 25.0976L20.5989 25.0993H20.6123C20.6123 25.0993 20.7997 25.0893 20.614 25.0993C20.9246 25.0991 21.2291 25.0125 21.4933 24.8491C21.7575 24.6856 21.971 24.4519 22.1099 24.174L23.231 21.9318L30.6535 23.17V30.3986C27.1213 30.909 17.5804 31.4126 10.0842 23.9163C2.58804 16.42 3.09002 6.87721 3.60203 3.34657ZM12.3699 14.1995L15.3934 12.1849C16.0322 11.7588 16.505 11.126 16.7326 10.3927C16.9602 9.6594 16.9287 8.87012 16.6434 8.15728L14.2222 2.10332C13.9737 1.48237 13.5449 0.950117 12.991 0.575212C12.4372 0.200308 11.7837 -4.48331e-05 11.1149 7.52514e-09H3.51502C1.99404 7.52514e-09 0.563408 1.05584 0.314093 2.70571C-0.254812 6.45722 -1.02618 17.5377 7.71823 26.2823C16.4626 35.0269 27.5429 34.2539 31.2943 33.6866C32.9442 33.4356 34 32.0066 34 30.4856V23.17C34.0001 22.3778 33.7192 21.6113 33.2072 21.0067C32.6952 20.4022 31.9854 19.9989 31.204 19.8686L23.7815 18.6321C23.0755 18.5142 22.3504 18.6258 21.7125 18.9505C21.0747 19.2751 20.5577 19.7957 20.2375 20.4359L19.6586 21.5954C19.4538 21.545 19.2512 21.4864 19.0512 21.4197C18.0138 21.0751 16.5731 20.3656 15.104 18.8964C13.6349 17.4273 12.9254 15.9866 12.5807 14.9475C12.4984 14.7018 12.4286 14.4522 12.3715 14.1995H12.3699Z" fill="#0D0D0D" />
                        </svg>

                      </div>
                      <div className="grow shrink text-2xl font-black text-red-600 w-[202px]">
                        +201224508000
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-10 items-center self-center mt-12 max-md:mt-10">
                    <div className="flex flex-col justify-center self-stretch px-1 py-1 my-auto   cursor-pointer border-2 border-transparent rounded-lg hover:border-red-600">
                      <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.9661 28.1215H23.9322V20.4637C23.9322 18.6377 23.8944 16.2873 21.3067 16.2873C18.683 16.2873 18.2826 18.2747 18.2826 20.3298V28.1215H13.2468V12.375H18.0823V14.52H18.1484C18.8247 13.2843 20.468 11.9772 22.9236 11.9772C28.0236 11.9772 28.968 15.2368 28.968 19.4773V28.1215H28.9661ZM7.56122 10.2208C7.17694 10.2213 6.79633 10.1482 6.44123 10.0056C6.08613 9.86305 5.7635 9.65386 5.49186 9.39003C5.22022 9.12621 5.0049 8.81294 4.85824 8.46819C4.71158 8.12344 4.63648 7.75398 4.63722 7.381C4.6376 6.8197 4.80945 6.27111 5.13105 5.8046C5.45266 5.3381 5.90957 4.97463 6.444 4.76016C6.97844 4.5457 7.56639 4.48986 8.13352 4.59972C8.70065 4.70958 9.22147 4.9802 9.63014 5.37736C10.0388 5.77452 10.3169 6.28038 10.4294 6.83097C10.5419 7.38155 10.4836 7.95215 10.2619 8.47058C10.0403 8.98902 9.66519 9.43202 9.18414 9.74356C8.70308 10.0551 8.13953 10.2212 7.56122 10.2208ZM10.0848 28.1215H5.03578V12.375H10.0867V28.1215H10.0848ZM31.4878 0H2.51033C1.12011 0 0 1.06333 0 2.37783V30.6222C0 31.9367 1.122 33 2.50844 33H31.4802C32.8667 33 34 31.9367 34 30.6222V2.37783C34 1.06333 32.8667 0 31.4802 0H31.4878Z" fill="black" />
                      </svg>

                    </div>
                    <div className="flex flex-col justify-center self-stretch   cursor-pointer border-2 border-transparent rounded-lg hover:border-red-600">
                      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 15C19.134 15 16 18.134 16 22C16 25.866 19.134 29 23 29C26.866 29 30 25.866 30 22C30 18.134 26.866 15 23 15ZM18.8 22C18.8 24.3197 20.6803 26.2 23 26.2C25.3197 26.2 27.2 24.3197 27.2 22C27.2 19.6803 25.3197 17.8 23 17.8C20.6803 17.8 18.8 19.6803 18.8 22Z" fill="black" />
                        <path d="M32 11C30.8954 11 30 11.8954 30 13C30 14.1046 30.8954 15 32 15C33.1046 15 34 14.1046 34 13C34 11.8954 33.1046 11 32 11Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1818 5C9.76769 5 7 7.76769 7 11.1818V32.8182C7 36.2322 9.76769 39 13.1818 39H34.8182C38.2322 39 41 36.2322 41 32.8182V11.1818C41 7.76769 38.2322 5 34.8182 5H13.1818ZM34.8182 8.09091H13.1818C11.4748 8.09091 10.0909 9.47476 10.0909 11.1818V32.8182C10.0909 34.5253 11.4748 35.9091 13.1818 35.9091H34.8182C36.5253 35.9091 37.9091 34.5253 37.9091 32.8182V11.1818C37.9091 9.47476 36.5253 8.09091 34.8182 8.09091Z" fill="black" />
                      </svg>
                    </div>


                    <svg className="cursor-pointer border-2 border-transparent rounded-lg hover:border-red-600" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.237 38.5V25.729H32.5142L33.154 20.7643H28.237V17.5963C28.237 16.159 28.6367 15.1782 30.6973 15.1782H33.3263V10.7378C32.0536 10.6028 30.7745 10.5373 29.4947 10.5417C25.7052 10.5417 23.111 12.8553 23.111 17.105V20.7643H18.8247V25.729H23.111V38.5H7.33333C6.8471 38.5 6.38079 38.3068 6.03697 37.963C5.69315 37.6192 5.5 37.1529 5.5 36.6667V7.33333C5.5 6.8471 5.69315 6.38079 6.03697 6.03697C6.38079 5.69315 6.8471 5.5 7.33333 5.5H36.6667C37.1529 5.5 37.6192 5.69315 37.963 6.03697C38.3068 6.38079 38.5 6.8471 38.5 7.33333V36.6667C38.5 37.1529 38.3068 37.6192 37.963 37.963C37.6192 38.3068 37.1529 38.5 36.6667 38.5H28.237Z" fill="black" />
                    </svg>

                    <div className="flex gap-2 justify-center items-center self-stretch py-2 pr-1.5 pl-2 my-auto min-h-[46px] w-[46px]">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/30c19a391a284ad46458458a98a19d079ffaac73307c0ed373effa566c2d2e28?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                        className="object-contain self-stretch my-auto aspect-square w-[33px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative self-center mt-16 mb-0 w-full max-w-[1670px] max-md:mt-10 max-md:mb-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <form onSubmit={handleSubmit} className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex relative flex-wrap gap-4 items-start w-full text-2xl font-extralight text-black max-md:mt-10 max-md:max-w-full">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 self-stretch px-7 py-6 rounded-2xl bg-stone-300 min-h-[68px] min-w-[240px] w-[296px] max-md:px-5"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 self-stretch px-5 py-6 rounded-2xl bg-stone-300 min-h-[68px] min-w-[240px] w-[296px]"
                    required
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 self-stretch px-7 py-6 rounded-2xl bg-stone-300 min-h-[68px] min-w-[240px] w-[296px] max-md:px-5"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 self-stretch px-5 py-6 rounded-2xl bg-stone-300 min-h-[68px] min-w-[240px] w-[296px]"
                    required
                  />
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 self-stretch px-6 py-6 rounded-2xl bg-stone-300 min-h-[68px] min-w-[240px] w-[698px] max-md:px-5 max-md:max-w-full"
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="overflow-hidden grow shrink gap-2 px-6 pt-8 pb-36 rounded-2xl bg-stone-300 min-h-[193px] min-w-[240px] w-[698px] max-md:px-5 max-md:pb-24 max-md:max-w-full"
                    required
                  />
                  {isLoading ? <button type="submit" className="mt-4 px-6 py-3  rounded-2xl bg-transparent text-red-600 border border-red-600" disabled>
                    Loading ...
                  </button> : <button type="submit" className="mt-4 px-6 py-3 bg-red-500 text-white rounded-2xl hover:bg-transparent hover:text-red-600 border border-red-600">
                    Send Message
                  </button>}


                </div>
              </form>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1930349.2647546222!2d-120.25528595680636!3d37.38134289490853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2z2LPYp9mGINmB2LHYp9mG2LPZitiz2YPZiNiMINmD2KfZhNmK2YHZiNix2YbZitin2Iwg2KfZhNmI2YTYp9mK2KfYqiDYp9mE2YXYqtit2K_YqQ!5e0!3m2!1sar!2seg!4v1730562982118!5m2!1sar!2seg" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9e73bd159ac249e9ad427555223eda81bab9a43f672a5f57e8251a1c2402903?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
          className="object-contain w-full aspect-[4.46] max-md:mt-10 max-md:max-w-full"
        />

      </div>

    </>
  );
}
export default ContactUs