import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import './index.css'
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";


function TechServiceSlider() {
  const slideTechService = [
    "Web Develppment", "AI and Machine Learning Solutions", "Mobile Applications", "DevOps Services", "UI and UX Design", "Content Management Systems (CMS)", "Software Development", "CRM and ERP Integration", "E-Commerce Solutions", "API Development and Integration", "AR and VR Development", "ML Model Training"
  ];

  return (
    <div className="container">
      <Swiper
        loop={true}
        spaceBetween={50}
        slidesPerView={6}
        onSlideChange={() => console.log("slide")}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[EffectCoverflow, Pagination, Navigation]}
        navigation={{
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
          clickable: true,
        }}
        className="swiper_container swiper_container_img cursor-pointer tech"
      >
        {slideTechService.map((item, index) => (
          <SwiperSlide key={index} style={{ transition: "none", filter: "unset", height: "15rem", gap: "40px" }}>
            <div
              className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[270px] min-w-[270px] cursor-pointer gap-1"
              style={{ flexShrink: 0, flexGrow: 0 }}
            >
              <div className=" w-full h-full gap-4">
                {index % 2 === 0 ? (
                  <>
                    <svg
                      className="ps-5 -mr-10"
                      width="188"
                      height="28"
                      viewBox="0 0 188 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                        stroke="black"
                      />
                      <circle
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="matrix(1 0 0 -1 0 18.1841)"
                        fill="black"
                      />
                      <path
                        d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                        fill="black"
                      />
                    </svg>
                    <div className="text-center mt-2">{item}</div>
                  </>
                ) : (
                  <>
                    <div className="text-center mb-2">{item}</div>
                    <svg
                      className="ps-5"
                      width="188"
                      height="28"
                      viewBox="0 0 188 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                        stroke="black"
                      />
                      <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                      <path
                        d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                        fill="black"
                      />
                    </svg>
                  </>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* <div className="slider-controler ">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="chevron-back-outline"></ion-icon>
            <svg width="48" height="18" viewBox="0 0 48 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4141 9.707H3.82806L10.1211 16L8.70706 17.414L6.10352e-05 8.707L8.70706 0L10.1211 1.414L3.82806 7.707H47.4141V9.707Z" fill="#2F2F2F" />
            </svg>
          </div>
          <div className="swiper-button-next slider-arrow">
            <svg width="48" height="18" viewBox="0 0 48 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.414001 9.707H44L37.707 16L39.121 17.414L47.828 8.707L39.121 0L37.707 1.414L44 7.707H0.414001V9.707Z" fill="#2F2F2F" />
            </svg>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div> */}

      </Swiper>
      <div className="flex justify-center items-center gap-32">
        <div className="swiper-button-prev2 cursor-pointer"><svg width="48" height="18" viewBox="0 0 48 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.4141 9.707H3.82806L10.1211 16L8.70706 17.414L6.10352e-05 8.707L8.70706 0L10.1211 1.414L3.82806 7.707H47.4141V9.707Z" fill="#2F2F2F" />
        </svg></div>
        <div className="swiper-button-next2 cursor-pointer"><svg width="48" height="18" viewBox="0 0 48 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.414001 9.707H44L37.707 16L39.121 17.414L47.828 8.707L39.121 0L37.707 1.414L44 7.707H0.414001V9.707Z" fill="#2F2F2F" />
        </svg></div>
      </div>
    </div>
  );
}
export default TechServiceSlider;