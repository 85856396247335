import React from "react";

function AboutUs() {
  return (
    <div className="flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24">
      <div className="flex overflow-hidden relative flex-wrap gap-40 justify-center items-start self-stretch pt-2 pr-1.5 pl-8 mt-28 w-full text-5xl font-black leading-none text-center text-red-600 min-h-[118px] max-md:pl-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dc12134a5749b8600b686b441ad77f91d8403a43e5e23e8bd667d21a15cc299?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
          className="object-contain absolute left-0 bottom-4 z-0 aspect-[8.77] h-[74px] min-w-[240px] w-[651px] max-md:max-w-full"
        />
        <div className="min-w-[240px] max-md:text-4xl">ABOUT US</div>
      </div>

      <div className="flex flex-col">
        <div className="flex z-10 flex-col justify-center ml-12 max-w-full w-[556px]">
          <div className="gap-2 self-stretch py-2 w-full text-5xl font-black text-red-600 uppercase max-md:max-w-full max-md:text-4xl mt-32">
            <span className="text-black font-normal">Welcome to</span> switch
          </div>
          <div className="gap-2 self-stretch py-2 pr-0.5 pl-1 mt-4 w-full text-base font-extralight text-black max-md:max-w-full capitalize">
            Where innovation meets inspiration. We’re not just a company, We’re
            pioneers of
            {/* <br /> */}
            the future, redefining the way technology shapes our world. At the
            heart of
            <br />
            our vision stands Zenith, Our AI-powered assistant and the
            embodiment of
            <br />
            our commitment to innovation and connectivity
          </div>
        </div>
        <div className="w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center p-2 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7831926e4b34b64750c46fc1e22a5b38a38219baacc0be592b6a11f532043a8b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                // className="object-contain w-full aspect-[1.32] max-md:max-w-full"
                />
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[40%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col mt-80 w-full max-md:mt-10 max-md:max-w-full">
                <div className="w-full text-5xl font-black text-red-600 uppercase min-h-[100px] max-md:max-w-full max-md:text-4xl">
                  Our Philosophy
                </div>
                <svg className="-mt-16" width="640" height="121" viewBox="0 0 640 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1636.87 121H2077V117.73H1638.45L1499.97 0H534.043L458.33 64.3702H0V67.6405H459.902L535.616 3.27026H1498.4L1636.87 121Z" fill="#2F2F2F" />
                </svg>

                <div className="gap-2 self-stretch mt-4 max-w-full text-base font-extralight text-black w-[608px] max-md:max-w-full">
                  &lt;body&gt;
                  <br />
                  &lt;div class=”container”&gt; <br />
                  &lt;h1&gt;
                  <span className="text-red-600">Welcome to the Future</span>
                  &lt;/h1&gt;
                  <br />
                  &lt;p&gt;
                  <span className="text-red-600">
                    Experience innovation like never before
                  </span>
                  .&lt;/p&gt;
                  <br />
                  &lt;button class=”cta-btn“&gt;Learn More&lt;/button&gt;
                  <br />
                  &lt;/”
                  <span className="text-red-600">
                    This is the language we talk, the way we feel, and the
                    visions we hold
                  </span>
                  .”&gt;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-44 max-md:flex-col">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
            <div className="flex relative gap-2  px-11 py-2.5 w-full text-5xl font-black text-red-600 uppercase min-h-[69px] max-md:px-5 max-md:max-w-full max-md:text-4xl">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0a0b81d406641ffea547fae3d4f3d61d1a65ede2b9c6e4946605ce561a71782b?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
                className="object-contain absolute   bottom-0 z-0  aspect-[7.81] h-[69px] min-w-[240px] w-[540px] max-md:max-w-full"
              />
              <div className="z-0 my-auto w-[346px] max-md:text-4xl">
                Our mission
              </div>
            </div>
            <div className="self-start ps-10 mt-4 text-base font-extralight  text-black">
              To pioneer the future of the digital industry, we leverage
              <br />
              AI and technology to craft innovative campaigns that
              <br />
              captivate audiences worldwide. We strive to push the
              <br />
              boundaries of creativity, delivering impactful solutions
              <br />
              that propel brands to new heights in the digital landscape.
              <br />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
            <div className="flex relative gap-2 items-end justify-end px-8 py-2.5 w-full text-5xl font-black text-red-600 uppercase min-h-[69px] max-md:px-5 max-md:max-w-full max-md:text-4xl">
              <img
                src="/images/Group 14.png"
                width={300}
                height={300}
                className="object-contain absolute   bottom-0 z-0  aspect-[7.81] h-[69px] min-w-[240px] w-[540px] max-md:max-w-full"
                alt=""
              />
              <div className="z-0 my-auto w-[346px] max-md:text-4xl">
                Our Vision
              </div>
            </div>
            <div className="self-end pe-10 mt-4 text-base font-extralight text-black text-justify">
              In the era of global digital transformation,
              <br />
              at Switch Advertising, we envision a future where
              <br />
              AI and technology drive limitless creativity, inspiring
              <br />
              a global audience. We are committed to leading the way
              <br />
              in cutting-edge advertising, setting new standards that
              <br />
              redefine our digital world.
            </div>
          </div>
        </div>
      </div>


      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full ">
          <div className="gap-2 self-stretch p-2 my-auto mr-0 text-5xl text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl ps-11 text-nowrap leading-tight">
            We’ve arrived from
            <br />
            <span className="font-black text-red-600 ">the future</span> to
            elevate
            <br />
            the present, using our
            <br />
            <span className="font-black text-red-600 text-nowrap">
              innovation and technology
            </span>
          </div>
        </div>
        <div className="flex flex-col  w-3/5 ">
          <img
            className="z-10"
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7e0e03d5ac96f3ce5b1b53820c3ceeda616c612abbd94089bb8c01c9c929d0e?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
          // className="object-contain w-full aspect-[1.16] max-md:max-w-full"
          />

        </div>
      </div>

      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f54f861cb30a9ceb7c592850aab84409ed4bab4a57e96ec14be79426ad49fcd6?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
        className="object-contain w-full aspect-[5.59] max-md:mt-10 max-md:max-w-full -mt-80"
      />
    </div>
  );
}

export default AboutUs;
