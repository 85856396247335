import React from 'react'


function Services() {
  return (
    <div className="flex overflow-hidden flex-col py-32 bg-gray-200 max-md:py-24">
      <div className="flex flex-col text-5xl  max-md:text-4xl mt-28">
        <div className="flex overflow-hidden flex-col justify-center items-end self-center max-w-full font-black text-red-600 uppercase max-md:text-4xl">
          <div className="flex flex-col items-start w-full max-md:text-4xl">
            <div className="overflow-hidden self-stretch w-full max-md:max-w-full max-md:text-4xl">
              Our services
            </div>
          </div>
        </div>
        <div className="gap-2 self-stretch p-2 mt-1.5 w-full text-center text-black leading-[85px] max-md:max-w-full max-md:text-4xl max-md:leading-[76px]">
          Transforming Industries With
          <br />
          Innovative Technology Solutions
        </div>
      </div>

      {/* Tech Service */}
      <div className="flex flex-col text-5xl font-black leading-none text-center text-black max-md:pl-5 max-md:text-4xl">
        <div className="flex overflow-hidden relative flex-wrap gap-0 items-start pr-96 pl-10 w-full max-w-[937px] min-h-[122px] max-md:px-5 max-md:max-w-full max-md:text-4xl">
          <div className="self-stretch -mb-16  max-md:text-4xl">
            <span className="text-red-600">Tech</span>{" "}
            <span className="text-zinc-600">Services</span>
          </div>
          <svg className='-ms-16' width="1200" height="74" viewBox="0 0 850 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M659.156 0H930.5V2H660.126L574.753 74H-20.7546L-67.4333 34.6331H-350V32.6331H-66.4639L-19.7853 72H573.784L659.156 0Z" fill="#2F2F2F" />
          </svg>

        </div>
      </div>
      <div className="relative ">
        <div className="flex justify-center flex-wrap  gap-9 items-center mt-16 w-full text-xl font-bold text-center text-black px-3 max-md:mt-10 max-md:max-w-full ">
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full ">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Web Developement</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">AI and Machine Learning Solutions</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Mobile Application</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">DevOps Services</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">UI And UX Design</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Content Management Systems (CMS)</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">Software Development</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">CRM and ERP Integration</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">E-Commerce Solutions </div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">API Development and Integration</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">AR And VR Developement</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">ML Model Training</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>

        </div>

      </div>

      {/* Digtal marketing Services */}
      <div className="flex flex-col text-5xl mt-20 font-black leading-none text-center text-black max-md:pl-5 max-md:text-4xl">
        <div className="flex overflow-hidden relative flex-wrap gap-0 items-start pl-8 w-full  min-h-[122px] max-md:px-5 max-md:max-w-full max-md:text-4xl justify-between">
          <div className="self-stretch mt-10  max-md:text-4xl">
            <span className="text-red-600">Digtal Marketing</span>{" "}
            <span className="text-zinc-600">Services</span>
          </div>
          <div className='-pl-52'>
            <svg width="837" height="74" viewBox="0 0 837 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1009.66 0H1281V2H1010.63L925.253 74H329.745L283.067 34.6331H0.5V32.6331H284.036L330.715 72H924.284L1009.66 0Z" fill="#2F2F2F" />
            </svg>
          </div>


        </div>
      </div>
      <div className="relative ">
        <div className="flex justify-center flex-wrap gap-9 items-center mt-16 w-full text-xl font-bold text-center text-black px-3 max-md:mt-10 max-md:max-w-full ">
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Digital Strategy Services</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">Digital Media Buying Services</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Social Media Marketing (SMM)</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">Content Marketing</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Search Engine Optimization (SEO)</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Search Engine Marketing (SEM)</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">Video Marketing</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Affiliate Marketing</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <div className="text-center mb-2">Website and social media audit</div>
              <svg
                className="ps-5"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 26.6838H121.638L145.732 2.948H184M4 12.3159H101.795"
                  stroke="black"
                />
                <circle cx="2.5" cy="12.3159" r="2.5" fill="black" />
                <path
                  d="M188 2.81592C188 4.19663 186.881 5.31592 185.5 5.31592C184.119 5.31592 183 4.19663 183 2.81592C183 1.43521 184.119 0.315918 185.5 0.315918C186.881 0.315918 188 1.43521 188 2.81592Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Influencer Marketing</div>

            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center px-2 py-16 my-auto rounded-2xl bg-zinc-300 h-[187px] w-[370px] min-w-[270px] cursor-pointer hover:bg-red-600"
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            <div className=" w-full h-full">
              <svg
                className="ps-5 -mr-10"
                width="250"
                height="28"
                viewBox="0 0 188 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1.31615H121.638L145.732 25.052H184M4 15.6841H101.795"
                  stroke="black"
                />
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  transform="matrix(1 0 0 -1 0 18.1841)"
                  fill="black"
                />
                <path
                  d="M188 25.1841C188 23.8034 186.881 22.6841 185.5 22.6841C184.119 22.6841 183 23.8034 183 25.1841C183 26.5648 184.119 27.6841 185.5 27.6841C186.881 27.6841 188 26.5648 188 25.1841Z"
                  fill="black"
                />
              </svg>
              <div className="text-center mt-2">Email Marketing</div>

            </div>
          </div>


        </div>

      </div>

      <div className="flex flex-col justify-center self-stretch py-2 mt-16 w-full text-4xl font-medium text-white uppercase max-md:mt-10 max-md:max-w-full">
        <div className="flex relative flex-col gap-2.5 self-stretch px-2.5 py-24 w-full min-h-[275px] max-md:max-w-full">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d4124a5ab42920040d57ee689bc38713e7de3707d7706f56e9a16349bbe32ca?placeholderIfAbsent=true&apiKey=e969867fc0a145258ec2d2dcaf1c3295"
            className="object-cover absolute inset-0 size-full"
          />
          <span className="z-10 bg-transparent text-center text-nowrap">Automated work flow with just one click <br/> <span className="font-black text-red-600 ">Revolutionizing the way you do business </span></span>{" "}

        </div>
      </div>

    </div>
  )
}

export default Services
