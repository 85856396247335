import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import './index.css'

import slide_image_1 from "../assets/images/Frame 61.png";
import slide_image_2 from "../assets/images/Frame 62.png";
import slide_image_3 from "../assets/images/Frame 63.png";
import slide_image_4 from "../assets/images/Frame 65.png";
import slide_image_5 from "../assets/images/Frame 66.png";
import slide_image_6 from "../assets/images/Frame 67.png";
import slide_image_7 from "../assets/images/Frame 68.png";
import slide_image_8 from "../assets/images/Frame 69.png";
import slide_image_9 from "../assets/images/Frame 70.png";
import slide_image_10 from "../assets/images/Frame 71.png";

import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
function AssistantSlider() {
    const slideImages = [
        slide_image_1, slide_image_2, slide_image_3, slide_image_4, slide_image_5,
        slide_image_6, slide_image_7, slide_image_8, slide_image_9, slide_image_10
    ];

    return (
        <div className="container-2">
            <Swiper
                // effect={"coverflow"}
                // grabCursor={true}
                // centeredSlides={true}
                loop={true}
                // slidesPerView={"auto"}
                // coverflowEffect={{
                //     rotate: 0,
                //     stretch: 0,
                //     depth: 100,
                //     modifier: 2.5,
                // }}
                // pagination={{ el: ".swiper-pagination", clickable: true }}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                    clickable: true,
                }}
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={() => console.log("slide")}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container swiper_container_ass cursor-pointer"
            >
                {slideImages.map((image, index) => (
                    <SwiperSlide key={index} style={{ transition: "none", filter: "unset" , height:"10rem"}}>
                        <span className="slide">
                            <img src={image} alt={`slide_image_${index + 1}`} className="w-20 h-60" />
                        </span>
                    </SwiperSlide>
                ))}


                <div className="slider-controler ">
                    <div className="swiper-button-prev slider-arrow slider-arroww">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </div>
                    <div className="swiper-button-next swiper-button-nextt slider-arrow slider-arroww">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                     {/* <div className="swiper-pagination"></div>  */}
                </div>
            </Swiper>
        </div>
    );
}
export default AssistantSlider;